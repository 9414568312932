import { render, staticRenderFns } from "./profileMyPage.vue?vue&type=template&id=5d6f1e01&scoped=true"
import script from "./profileMyPage.vue?vue&type=script&lang=js"
export * from "./profileMyPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6f1e01",
  null
  
)

export default component.exports